<template>
  <v-dialog v-model="showModal" width="800px">
    <v-form ref="form" lazy-validation autocomplete="off">
      <v-card>
        <v-card-title>
          <span class="headline">{{ $tc('message.atualiza_pagamento', 1) }}</span>
        </v-card-title>
        <v-card-text>
          <v-container grid-list-md class="py-0">
            <v-row>
              <v-col cols="12" sm="12">
                <v-autocomplete
                  id="novo_status"
                  name="novo_status"
                  :items="listaStatus"
                  item-text="descricao"
                  item-value="value"
                  clearable
                  :disabled="isStatusAtualLiquidado || isStatusAtualCancelado"
                  v-model="objAtualizacao.novoStatus"
                  :rules="[rules.required]"
                  :label="`${$tc('label.status_pagamento', 1)}`">
                </v-autocomplete>
              </v-col>

              <v-col cols="12" sm="12" v-if="exibirDataLiquidacao">
                <v-menu
                  ref="data_liquidacao"
                  :close-on-content-click="false"
                  v-model="menuDataLiquidacao"
                  id="dp_data_liquidacao"
                  name="dp_data_liquidacao"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px">
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-on="on"
                      ref="dataLiquidacao"
                      class="Form-text__invalid"
                      :return-masked-value="true"
                      v-mask="'##/##/####'"
                      clearable
                      :rules="[rules.validDate]"
                      v-model="dataLiquidacaoFormatada"
                      :label="`${$t('label.dta_liquidacao')}`"
                      prepend-icon="event"
                      readonly>
                    </v-text-field>
                  </template>
                  <v-date-picker
                    locale="pt-br"
                    color="primary"
                    v-model="objAtualizacao.dataLiquidacao"
                    @input="menuDataLiquidacao = false">
                  </v-date-picker>
                </v-menu>
              </v-col>

              <v-col cols="12" sm="12" v-if="exibirNumeroDocumento">
                <v-text-field
                  maxlength="30"
                  counter=30
                  :label="$t('label.numero_documento')"
                  v-model="objAtualizacao.numeroDocumento"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="12" v-if="exibirJustificativa">
                <v-textarea
                  id="justificativa_obrigatoria"
                  name="justificativa-obrigatoria"
                  :label="`${$tc('label.justificativa', 1)} *`"
                  v-model="objAtualizacao.justificativa"
                  auto-grow
                  autofocus
                  rows="1"
                  counter="250"
                  :disabled="isStatusAtualCancelado"
                  :rules="[rules.required, rules.maxLength]"
                ></v-textarea>
              </v-col>
              <v-col cols="12" sm="12" v-if="exibirDataLiquidacao">
                <v-text-field
                  :label="$t('label.acordo')"
                  v-model="objAtualizacao.acordo"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" v-if="exibirDataLiquidacao">
                <v-text-field
                  :label="$t('label.valor_credito_gerado')"
                  v-model="objAtualizacao.valorCreditoGerado"
                  :required="false"
                  :disabled="false"></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn @click="cancela">
            {{ $t('label.cancelar') }}
          </v-btn>

          <v-btn color="primary" @click="atualizarStatus">
            {{ $t('label.aplicar') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script>
import {
  formatDateDDMMYYYY,
  parseDateYYYYMMDD,
  formatDatePattern,
} from '../../../common/functions/date-utils';
import InputMoney from '../../../shared-components/inputs/InputMoney';

export default {
  name: 'ModalRateioNovaDivisao',
  props: {
    acao: Object,
  },
  data() {
    return {
      pagamentoCadastroResource: this.$api.pagamentoCadastro(this.$resource),
      showModal: false,
      menuDataLiquidacao: false,
      objAtualizacao: {},
      listaStatus: [
        { value: 'APROVADO', descricao: this.$tc('status_entidade.aprovado', 1) },
        { value: 'AGUARDANDO_PAGAMENTO', descricao: this.$t('status_entidade.aguardando_pagamento') },
        { value: 'LIQUIDADO', descricao: this.$t('status_entidade.liquidado') },
        { value: 'BLOQUEADO', descricao: this.$t('status_entidade.bloqueado') },
        { value: 'CANCELADO', descricao: this.$t('status_entidade.cancelado'), disabled: !this.acao.pagamentoCancelavel },
      ],
      rules: {
        required: (value) => !!value || this.$t('message.campo_obrigatorio'),
        maxLength: (value) => ((!!value && value.length <= 250) || !value) || this.$t('message.limite_caracteres_excedido'),
        validDate: (value) => (!value || (value.length === 10 && this.moment(value, 'DD-MM-YYYY').isValid())) || this.$t('message.data_invalida'),
      },
    };
  },
  computed: {
    isStatusAtualLiquidado() {
      return this.objAtualizacao.status === 'LIQUIDADO';
    },
    isStatusAtualCancelado() {
      return this.objAtualizacao.status === 'CANCELADO';
    },
    isNovoStatusLiquidado() {
      return this.objAtualizacao.novoStatus === 'LIQUIDADO';
    },
    isNovoStatusAprovado() {
      return this.objAtualizacao.novoStatus === 'APROVADO';
    },
    isNovoStatusAguardandoPagamento() {
      return this.objAtualizacao.novoStatus === 'AGUARDANDO_PAGAMENTO';
    },
    isNovoStatusBloqueado() {
      return this.objAtualizacao.novoStatus === 'BLOQUEADO';
    },
    isNovoStatusCancelado() {
      return this.objAtualizacao.novoStatus === 'CANCELADO';
    },
    exibirDataLiquidacao() {
      return this.isNovoStatusLiquidado || this.isNovoStatusAprovado;
    },
    exibirNumeroDocumento() {
      return this.isNovoStatusLiquidado || this.isNovoStatusAguardandoPagamento || this.isNovoStatusAprovado;
    },
    exibirJustificativa() {
      return this.isNovoStatusBloqueado || this.isNovoStatusCancelado;
    },
    dataLiquidacaoFormatada: {
      get() {
        if (!this.objAtualizacao.dataLiquidacao) {
          return '';
        }
        if (this.objAtualizacao.dataLiquidacao.includes('/')) {
          const dataLiquidacaoFormat = this.objAtualizacao.dataLiquidacao;
          this.objAtualizacao.dataLiquidacao = parseDateYYYYMMDD(dataLiquidacaoFormat);
          return dataLiquidacaoFormat;
        }
        return formatDatePattern(this.objAtualizacao.dataLiquidacao, 'YYYY-MM-DD');
      },
      set(newValue) {
        if (newValue != null) {
          this.objAtualizacao.dataLiquidacao = formatDateDDMMYYYY(newValue);
        } else {
          this.objAtualizacao.dataLiquidacao = '';
        }
      },
    },
  },
  components: {
    InputMoney,
  },
  methods: {
    formatDatePattern,
    open() {
      this.formatarObjeto();
      this.showModal = true;
      this.$forceUpdate();
    },
    cancela() {
      this.showModal = false;
    },
    formatarObjeto() {
      this.objAtualizacao = {
        idAcao: this.acao.idAcao,
        idApuracao: this.acao.idApuracao,
        idPagamento: this.acao.idPagamento,
        status: this.acao.statusPagamento,
        novoStatus: this.acao.statusPagamento,
        justificativa: this.acao.justificativa ? this.acao.justificativa : '',
        dataLiquidacao: this.acao.dataLiquidacao ? parseDateYYYYMMDD(this.acao.dataLiquidacao) : '',
        numeroDocumento: this.acao.numeroDocumento ? this.acao.numeroDocumento : '',
        acordo: this.acao.acordo ? this.acao.acordo : '',
        valorCreditoGerado: this.acao.valorCreditoGerado ? this.acao.valorCreditoGerado : '',
      };
    },
    atualizarStatus() {
      if (!this.$refs.form.validate()) return;
      if (!this.exibirDataLiquidacao) {
        this.objAtualizacao.dataLiquidacao = '';
      } else {
        this.objAtualizacao.dataLiquidacao = this.dataLiquidacaoFormatada;
      }
      if (!this.exibirJustificativa) {
        this.objAtualizacao.justificativa = '';
      }
      const params = {
        ...this.objAtualizacao,
      };
      this.pagamentoCadastroResource.atualizarStatusPagamento(params)
        .then(() => {
          this.$toast(this.$tc('message.pagamento_atualizado_com_sucesso', 1));
          this.showModal = false;
          this.$emit('RECARREGAR_PAGAMENTO', this.objAtualizacao.novoStatus);
        })
        .catch((err) => {
          this.$toast(this.$t(err.data.error));
        });
    },
  },
  mounted() {
    this.formatarObjeto();
  },
};
</script>
