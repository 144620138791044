<template>
  <v-expansion-panel>
    <v-expansion-panel-header class="headline">
      <div>
        <span>{{ $tc('label.pagamento', 1)}}</span>
      </div>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <metadados-container-layout
        v-if="carregouMetadados"
        :metadados-entidade="metadadosInterno"
        :campos-formulario="camposFormulario"
        :ordenacao-campos="ordenacaoCampos"
        :layout-class="layoutClass"
        ignora-obrigatorios
        :input-layout="inputLayout"
        :somenteLeitura="true"
        :objeto="acaoFomatada"
        ref="container">
      </metadados-container-layout>
      <div v-if="itens[0]" class="d-flex" style="margin-top: 20px;margin-bottom: 20px;">
        <div v-if="itens[0]" style="width: 300px;">
          <strong><span>{{ $t('label.numero_documento') }}</span></strong>
        </div>
        <div v-if="itens[0]" style="width: 300px;">
          <strong><span>{{ $t('label.valor_credito_gerado') }}</span></strong>
        </div>
        <div v-if="itens[0]" style="width: 300px;">
          <strong><span>{{ $t('label.data_liquidacao') }}</span></strong>
        </div>
      </div>
      <div v-for="(item, index) in  itens" class="d-flex">
        <div v-if="item.numeroDocumento" style="width: 300px;">
          <span>{{ item.numeroDocumento }}</span>
        </div>
        <div v-if="item.valorCreditoGerado" style="width: 300px;">
          <span>{{ converteMoeda(item.valorCreditoGerado) }}</span>
        </div>
        <div v-if="item.dataLiquidacao" style="width: 300px;">
          <span>{{ item.dataLiquidacao }}</span>
        </div>
      </div>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import { copyObject, getMoney } from '../../../common/functions/helpers';
import MetadadosContainerLayout from '../../../shared-components/metadados/MetadadosContainerLayout';

export default {
  props: {
    acao: Object,
    configuracao: Object,
    metadados: {
      type: Object,
      default: {},
    },
  },
  components: {
    MetadadosContainerLayout,
  },
  data() {
    return {
      resources: this.$api.parametrizacaoAcao(this.$resource),
      camposFormulario: {},
      ordenacaoCampos: [],
      camposParametrizacao: [],
      acaoFomatada: {},
      metadadosInterno: null,
      carregouMetadados: false,
      layoutClass: { wrap: true },
      itens: [],
      inputLayout: {
        xs12: true,
        sm12: false,
        md12: false,
        sm6: true,
        md3: true,
        md4: false,
        md6: false,
        md9: false,
      },
    };
  },
  watch: {
    acao() {
      this.carregouMetadados = false;
      setTimeout(this.carregarComponente, 2E2);
    },
  },
  computed: {
    getCampos() {
      return this.configuracao.passo6.liquidacao.camposDinamicosPagamentoManual || [];
    },
    getOrdemCampos() {
      return this.configuracao.passo7.listaLiquidacaoManual || [];
    },
  },
  methods: {
    getMoney,
    converteMoeda(item) {
      const valor = Number(item);
      if (Number.isNaN(valor)) {
        return `${'R$ '}${item}`;
      }
      return getMoney(valor);
    },
    toDate(data) {
      return this.moment(data, 'YYYY-MM-DD').format('DD/MM/YYYY');
    },
    buscarCampos() {
      const { tipoAcao } = this.acao;
      this.resources.buscarCampos({ tipoAcao }, { tipoAcao })
        .then((response) => {
          this.camposParametrizacao = response.body;
          this.carregarComponente();
        });
    },
    setMetadados() {
      this.metadadosInterno = copyObject(this.metadados);
      const camposDinamicos = {};
      if (this.metadadosInterno) {
        const campos = copyObject(this.metadadosInterno.mapaCamposDinamicos);
        if (this.getCampos) {
          this.getCampos.forEach((campo) => {
            if (campos[campo.label] && this.acaoFomatada[campo.label]) {
              camposDinamicos[campo.label] = campos[campo.label];
            }
          });
        }
        this.metadadosInterno.mapaCamposDinamicos = camposDinamicos;
        this.metadadosInterno.mapaEntidades = {};
      }
    },
    preencheCamposFomularios() {
      const campos = [];
      this.itens = [];
      if (this.acao.notas) {
        const notas = JSON.parse(this.acao.notas.value);
        notas.forEach((e) => {
          this.itens.push({
            numeroDocumento: Object.keys(e)[0],
            valorCreditoGerado: Object.values(e)[0].split(':')[0],
            dataLiquidacao: Object.values(e)[0].split(':')[1] ? Object.values(e)[0].split(':')[1] : '-------------------------',
          });
        });
      }
      if (this.acaoFomatada.pendente_pagamento) {
        campos.push({
          labelCampo: 'pendente_pagamento',
          tipoCampo: 'DINHEIRO',
        });
      }
      if (this.acaoFomatada.total_pagamento) {
        campos.push({
          labelCampo: 'total_pagamento',
          tipoCampo: 'DINHEIRO',
        });
      }
      if (this.acaoFomatada.status_pagamento) {
        campos.push({
          labelCampo: 'status_pagamento',
          tipoCampo: 'LISTA',
          desAtributos: {
            textoItem: 'texto',
            valorItem: 'valor',
            lista: [
              {
                texto: this.$t('status_entidade_pagamento.aguardando_pagamento'),
                valor: 'AGUARDANDO_PAGAMENTO',
              },
              {
                texto: this.$tc('status_entidade_pagamento.aprovado', 1),
                valor: 'APROVADO',
              },
              {
                texto: this.$tc('status_entidade_pagamento.programado', 1),
                valor: 'PROGRAMADO',
              },
              {
                texto: this.$tc('status_entidade_pagamento.liquidado', 1),
                valor: 'LIQUIDADO',
              },
              {
                texto: this.$t('status_entidade_pagamento.bloqueado'),
                valor: 'BLOQUEADO',
              },
              {
                texto: this.$t('status_entidade_pagamento.cancelado'),
                valor: 'CANCELADO',
              },
            ],
          },
        });
      }
      if (this.acaoFomatada.evidencia) {
        campos.push({
          labelCampo: 'evidencia',
          tipoCampo: 'TEXTO',
        });
      }
      if (this.acaoFomatada.justificativa) {
        campos.push({
          labelCampo: 'justificativa',
          tipoCampo: 'TEXTO',
        });
      }
      if (this.acaoFomatada.acordo || this.camposParametrizacao.includes('acordo')) {
        campos.push({
          labelCampo: 'acordo',
          tipoCampo: 'TEXTO',
        });
      }
      this.camposFormulario.padrao = campos;
    },
    montarOrdenacao() {
      this.ordenacaoCampos = [];
      this.ordenacaoCampos.push('numero_documento');
      this.ordenacaoCampos.push(...this.getOrdemCampos
        .sort((a, b) => a.ordenacao < b.ordenacao)
        .map((c) => c.label));
      this.ordenacaoCampos.push('data_liquidacao');
      this.ordenacaoCampos.push('justificativa');
      this.ordenacaoCampos.push('acordo');
      this.ordenacaoCampos.push('valor_credito_gerado');
    },
    formatarObjeto() {
      const desAtributos = this.acao.desAtributosPagamento
        ? JSON.parse(this.acao.desAtributosPagamento.value) : {};

      const acaoFormatada = { ...this.acao, ...desAtributos };

      acaoFormatada.status_pagamento = acaoFormatada.statusPagamento;
      acaoFormatada.numero_documento = acaoFormatada.numeroDocumento;
      acaoFormatada.valor_credito_gerado = acaoFormatada.valorCreditoGerado;
      if (acaoFormatada.dataLiquidacao) {
        acaoFormatada.data_liquidacao = this.moment(acaoFormatada.dataLiquidacao, 'DD/MM/YYYY').format();
      }
      acaoFormatada.total_pagamento = acaoFormatada.totalPagamento;
      acaoFormatada.pendente_pagamento = acaoFormatada.pendentePagamento;

      this.acaoFomatada = acaoFormatada;
    },
    carregarComponente() {
      this.formatarObjeto();
      this.setMetadados();
      this.montarOrdenacao();
      this.preencheCamposFomularios();
      this.carregouMetadados = true;
    },
  },
  mounted() {
    this.buscarCampos();
  },
};
</script>
<style>
  .select--sem--linha > .v-input__control > .v-input__slot:before {
    width: 0% !important;
  }
  .select--sem--linha > .v-input__control > .v-input__slot:after {
    border-style: none;
  }
</style>
